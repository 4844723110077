import type { ComponentType } from "react"

export function homeContactUs(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                data-tally-open="wg4KeP"
                data-tally-layout="modal"
                data-tally-width="600"
                data-src_element="contact-cta"
                data-src_category="advisory"
                onClick={() => {
                    rudderanalytics.track("cta_click", {
                        product_category: "advisory",
                        element: "contact-cta",
                        cta_type: "contact",
                    })
                }}
            />
        )
    }
}

export function coursesTeamTraining(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                onClick={() => {
                    rudderanalytics.track("cta_click", {
                        product_category: "learning",
                        product_type: "team-training",
                        element: "course-b2b-cta",
                        cta_type: "scroll",
                    })
                }}
            />
        )
    }
}

export function workshops(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                data-tally-open="wg4KeP"
                data-tally-layout="modal"
                data-tally-width="600"
                data-src_element="workshop-pillars-cta"
                data-src_category="learning"
                onClick={() => {
                    rudderanalytics.track("cta_click", {
                        product_category: "learning",
                        product_type: "workshops",
                        element: "workshop-pillars-cta",
                        cta_type: "contact",
                    })
                }}
            />
        )
    }
}
